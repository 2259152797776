<template>
  <div style="display: flex;flex-direction: column;">
    <div style="display: flex;flex-direction: row; ">
      <div class="topNav2" id="topNav2">
        <ul id="topNavUl2">
          <li v-for="(i, index) in title" :key="index" :ref="('cardSecond-' + index)"
            @click="goCenter(i.id, index, $event)">
            <div style="display: flex;flex-direction: column;align-items: center;">
              <span :class="selectindex === index ? 'title-sty' : 'untitle-sty'">{{ i.name }}</span>
            </div>
          </li>
        </ul>
      </div>
      <div @click="showAll"
        style="padding: 0 0.2rem;background-color: #F8F8F8;align-items: center;padding: 0.2rem 0.8rem; ">
        <van-icon size="1rem" name="arrow-down" />
      </div>
    </div>
    <div v-if="ifShow" class="pop" :style="{ top: type == 2 ? '9.1rem' : '11.6rem' }">
      <div style="max-height: 12.5rem;overflow-y: scroll;">
        <van-grid :border="false" :column-num="3">
          <van-grid-item v-for="(i, index) in title" :key="index">
            <div style="display: flex;flex-direction: column;align-items: center; background-color: #F8F8F8;width: 85%;"
              @click="clickCard(index)">
              <span :class="selectindex === index ? 'btitle-sty' : 'noctitle-sty'">{{ i.name }}</span>
            </div>
          </van-grid-item>
        </van-grid>
      </div>
      <div @click="clossPop"
        style="width: 100%;text-align: center;padding-top: 1rem; font-size: 0.8rem; color: #aaaaaa;">点击收起<van-icon
          size="1rem" style="margin-left: 0.5rem;" name="arrow-up" /></div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    title: {
      type: Array
    },
    type: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      currentTopNav: '',
      selectindex: 0,
      ifShow: false,
      selectindex2: 0,
      getData: true
    }
  },
  created() { },
  components: {},
  methods: {
    showAll() {
      this.ifShow = true
      if (this.ifShow) {
        this.$emit('clossSecondPop')
      }
    },
    clickCard(index) {

      this.getData = true
      setTimeout(() => {
        this.$refs[('cardSecond-' + index)][0].click();
      }, 200);
    },
    indexTo(index) {
      this.getData = false
      this.$refs[('cardSecond-' + index)][0].click();
    },
    clossPop() {
      this.ifShow = false
    },
    goCenter(id, index, e) {
      if (this.ifShow) {
        this.ifShow = false
      }
      this.selectindex = index
      let destination = index - 2;
      destination = destination < 0 ? 1 : destination;
      this.currentTopNav = id;
      if (this.navScroll) {
        this.navScroll.scrollToElement(
          document.querySelector('#topNav li:nth-child(' + destination + ')')
        );
      }
      this.getdata(index);

      // 横向滑动居中
      let ul = document.querySelector('#topNavUl2');
      let nav = document.getElementById("topNav2");
      let window_offsetWidth = window.innerWidth; //屏幕宽度;
      let dom = e.target;
      if (dom && ul && nav) {
        let domoffsetWidth = dom.offsetLeft,
          //中间值 =( 屏幕宽度 - li宽度 ) / 2;
          diffWidth = (window_offsetWidth - dom.offsetWidth) / 2,
          //目标值 = offset - 中间值
          targetOffset = -(domoffsetWidth - diffWidth);
        let ul_width = ul.getBoundingClientRect().width;//开始

        // 未超出中间值
        if (-targetOffset < 0) {
          nav.scrollLeft = 0;
          return;
        }
        //末尾
        if (targetOffset < window_offsetWidth - ul_width) {
          nav.scrollLeft = -(window_offsetWidth - ul_width);
          return;
        }
        //正常
        nav.scrollLeft = -targetOffset
      }
    },
    getdata(index) {
      if (this.getData) {
        this.$emit('scrollTo', index)
      } else {
        this.getData = true
      }
    }

  },
  watch: {
    ifShow: function () {

    }
  },
  mounted() {
  },
}

</script>

<style lang="scss" scoped>
.topNav2 {

  background: #F8F8F8;
  display: flex;
  flex: 1;
  overflow: scroll;
  white-space: nowrap;

  ul {
    display: inline-block;
    white-space: nowrap;

    li {
      display: inline-block;
      font-size: 1rem;
      padding: 0 0.1rem;

      &:first-child {}

      &:last-child {}
    }
  }
}



::v-deep .van-grid-item__content {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  padding: 0.5rem 0;
  background-color: #F8F8F8;
}



.title-sty {

  border-radius: 1rem;
  background-color: #ece8e2;
  color: #d59a54;
  font-size: 0.85rem;

  padding: 0.2rem 0.8rem 0.25rem 0.8rem;
}

.untitle-sty {

  border-radius: 1rem;
  background-color: #fff;
  font-size: 0.85rem;
  color: #323844;
  padding: 0.2rem 0.8rem 0.25rem 0.8rem;
}

.noctitle-sty {

  border-radius: 1rem;
  background-color: white;
  font-size: 0.85rem;
  color: #323844;
  width: 100%;
  text-align: center;
  padding: 0.4rem;
}

.btitle-sty {

  border-radius: 1rem;
  background-color: #ffffff;
  color: #D59A54;
  font-size: 0.85rem;
  width: 100%;
  text-align: center;
  padding: 0.5rem;
}

.pop {
  width: 100%;
  position: absolute;
  top: 11.6rem;
  overflow-y: scroll;
  z-index: 9999;
  background-color: #F8F8F8;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  padding-bottom: 1rem;
}
</style>